<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'my-teams' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Создание команды</h1>
    </div>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label-for="name" label="Название команды" required>
            <app-input
              v-model.trim="form.name"
              id="name"
              placeholder="Введите название команды"
              :class="{ 'input--error': $v.form.name.$error }"
              @change.native="$v.form.name.$touch()"
            />
            <template #error>
              <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="desc" label="Описание команды">
            <app-textarea
              v-model.trim="form.desc"
              id="desc"
              placeholder="Опишите свою команду"
            />
          </app-form-group>
          <AppCheckbox
            v-model="form.is_public"
            name="is_public"
            label="Показать команду в публичной части"
          />
        </template>
        <template #item-2>
          <app-form-group label="Образовательная организация">
            <v-select
              label="name"
              v-model="form.org"
              :filterable="false"
              :options="org_options"
              :clearable="false"
              @search="onOrgSearch"
              @input="onSetOrg"
              placeholder="Введите название образовательной организации"
              class="select"
              :key="1"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                </div>
                <div v-else class="select__noty">
                  Для поиска образовательной организации в справочнике, введите наименование в формате: <span>МБОУ СОШ 1 Ставрополь</span> или <span>Ставропольский ГАУ</span> или ИНН организации и выберите значение из выпадающего списка.
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.address }})</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }} ({{ option.address }})</div>
              </template>
            </v-select>
          </app-form-group>
          <app-form-group label-for="region" label="Регион">
            <v-select
              v-model="form.region"
              :options="region_options"
              :filterable="false"
              :clearable="false"
              @search="onRegionSearch"
              @input="onSetRegion"
              label="address"
              class="select"
              placeholder="Выберите регион"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template #no-options="{ search, searching }">
                <div v-if="searching" class="select__noty">
                  По вашему запросу <em>{{ search }}</em> ничего не найдено.
                </div>
                <div v-else class="select__noty">
                  Для поиска региона в справочнике, введите адрес в формате: <span>Ставрополь, ул. Пушкина, д 65в, кв. 1</span> и выберите значение из выпадающего списка.
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.address }}</div>
              </template>
            </v-select>
          </app-form-group>
        </template>
      </app-grid>
      <AppCells>
        <AppButton ref="submit" :disabled="$v.form.$error">Добавить</AppButton>
      </AppCells>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { debounce } from 'lodash'

export default {
  name: 'TeamsCreate',
  data() {
    return {
      form: {},
      org_options: [],
      region_options: []
    }
  },
  validations: {
    form: {
      name: { required },
    },
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('teams/POST_DATA', this.form)
        .then(() => {
          this.$router.push({ name: 'my-teams' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Команда успешно создана.'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    onOrgSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_EDU_ORG', {search}).then(response => {
        vm.org_options = response.data
        loading(false)
      })
    }, 350),
    onSetOrg() {
      this.form.org_text = this.form.org.name
    },
    onRegionSearch (search, loading) {
      loading(true)
      this.searchRegion(loading, search, this)
    },
    searchRegion: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ADDRESS_REGION', {search}).then(response => {
        vm.region_options = response.data
        loading(false)
      })
    }, 350),
    onSetRegion() {
      this.form.region_text = this.form.region.region
    },
  }
}
</script>
